.input {
    border: solid 4px #3a3b3f;
    font-weight: 700;
    color: #373a41;
    border-radius: 16px;
    width: 100%;
    border-radius: 16px;
    outline: none;
    margin-top: 10px;
    height: 48px;
    padding: 0 15px;
    box-sizing: border-box;
}

.input:placeholder-shown {
    border-color: transparent;
    background-color: #161617;
    box-shadow: 0 0 0 1px #3a3b3f inset;
    appearance: none;
}

.input:hover {
    background-color: #3a3b3f;
    color: #fff;
}

.input:focus {
    background-width: 4px;
    background-color: white !important;
    border-color: #8c18f1;
    box-shadow: 0 0 0 2px rgb(115 30 218 / 50%) inset;
    color: #373a41 !important;
}

.input.has_error {
    background-color: #fff4d0;
    border: solid 2px #f35828;
    box-shadow: 0 0 0 2px rgb(229 25 77 / 50%) inset;
    color: #f35828;
}