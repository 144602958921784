.login {
    max-width: 540px;
    @apply w-full mx-auto
}

.login h3 {
    font-size: 2rem;
    font-weight: 700;
    margin-top: 1em;
    line-height: 1em;
    text-align: center;
}

.login h6 {
    margin: 24px 0 15px;
    text-align: center;
}

.forgot_password {
    font-size: 0.625rem;
    @apply text-primary text-center inline-block w-full
}