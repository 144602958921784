.text_button {
    display: inline-flex;
    flex-direction: column;
    color: #fff !important;
    cursor: pointer;
    border-radius: 16px;
    padding: 14px 17px;
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 30px;
    align-self: center;
    border: none;
    box-shadow: 0 0 0 1px #3a3b3f inset;
    background-color: transparent;
  }

  .text_button:hover {
    background: #3a3b3f;
    box-shadow: none;
  }

  .text_button.active {
    box-shadow: 0 0 0 2px #8c18f1 inset;
    background-color: #3a3b3f;
  }

  .text_button.left_rounded {
    border-radius: 16px 0 0 16px;
  }

  .text_button.right_rounded {
    border-radius: 0 16px 16px 0;
  }

  .text_button::after {
    display: inline-block;
    background: #8c18f1;
    border-radius: 20px;
    height: 5px;
    width: 100%;
    content: "";
    margin: 5px 0 2px;
  }

.primary:after {
    @apply bg-primary
}

.secondary:after {
    @apply bg-secondary
}