.form_content {
    border-radius: 24px 24px 4px 4px;
    padding: 3rem;
    @apply bg-base-300
}

.form_footer {
    margin-top: 2px;
    border-radius: 4px 4px 24px 24px;
    padding: 20px 0px;
    @apply bg-base-300
}